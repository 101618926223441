"use strict";

import { ready } from "@wearegood/good-utilities";

//import ShowhideAPI from "Modules/Showhide";
import ModalAPI from "Modules/Modal";
//import SmartImageAPI from "Modules/SmartImage";
import LayoutAPI from "Modules/Layout";
//import NavigationAPI from "Modules/Navigation";
//import CarouselAPI from "Modules/Carousel";
import LoaderAPI from "Modules/Loader";
import LanguageAPI from "Modules/Language";
//import GridAPI from "Modules/Grid";

/**
 * initialiseComponentModules - call module init functions
 *
 * @returns {type} Description
 */
function initialiseComponentModules() {
  //NavigationAPI.initialiseNavigation();
  //SmartImageAPI.initSmartImages();
  ModalAPI.initialiseModals();
  //CarouselAPI.initialiseCarousels();
  //ShowhideAPI.initialiseShowhide();
  LayoutAPI.initIntersectors();
  LoaderAPI.initLoader();
  LanguageAPI.initLanguage();
  //GridAPI.initGrids();

  // bindGlobalResizeMessage();

  // Wrap tables in container to allow overflow scroll
  // This is a small enough bit of functionality to put on it's own here.
  // But should be moved if more layout functionality warrants the creation
  // of a Layout module

  // const tables = document.querySelectorAll('.tx_Prose table');

  // tables.forEach(element => {
  //   const wrapper = document.createElement('div');
  //   wrapper.classList.add('ob_Table');
  //   wrapElement(element,wrapper);
  // });
}

ready(initialiseComponentModules);
