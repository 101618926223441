// Intersector module
"use strict";

////////////////////
// Module Imports //
////////////////////


import "nodelist-foreach-polyfill";
import "intersection-observer";

////////////////////////////////////
// Module Constants and Variables //
////////////////////////////////////

// Image Observer
const headerObserverOptions = {
  root: null,
  rootMargin: "10px 0px 10px 0px",
  threshold: 0.2
};

const CLASS_STICKY = "is_Sticky";

let headerObserver;

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

/**
 * Update the module pageScrollPosition variable to the current page scrollTop value
 *
 */
function updateScrollPosition() {
  pageScrollPosition = getBodyScrollTop();
}


/**
 * Get the current scroll position of the top of the viewport
 *
 * @returns
 */
function getBodyScrollTop() {
  const bodyElement = document.scrollingElement || document.documentElement;
  return bodyElement.scrollTop;
}

/**
 *
 *
 * @param {*} steps
 * @returns
 */
function buildThresholdList(steps) {
  var thresholds = [];
  var numSteps = steps;

  for (var i = 1.0; i <= numSteps; i++) {
    var ratio = i / numSteps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
}

/**
 * Instantiate an IntersectionObserver object for the Header
 *
 */
function initialiseHeaderObserver() {
  headerObserver = new IntersectionObserver(handleHeaderIntersection, headerObserverOptions);
  const headerElement = document.querySelector('.cp_TopMarker');

  if(headerElement) {
    headerObserver.observe(headerElement);
  }
}

function handleHeaderIntersection(entries, observer) {
  entries.forEach((entry) => {
    if (entry.intersectionRatio <= headerObserverOptions.threshold) {
      document.body.classList.add(CLASS_STICKY);
    } else {
      document.body.classList.remove(CLASS_STICKY);
    }
  });
}


/**
 * initModule - Initialise this module and the components contained in it
 *
 * @returns {type} Description
 */
export function initModule() {
  initialiseHeaderObserver();
}

export default { initModule: initModule };
