"use strict";

////////////////////
// Module Imports //
////////////////////

import Cookies from 'js-cookie';
import { createGlobalMessenger } from "@wearegood/good-utilities";
import PubSub from "pubsub-js";

//////////////////////
// Module Constants //
//////////////////////

const LANGUAGE_LINK_SEL = "[data-language]";
const LANGUAGE_PAGE_SEL = "#languagePicker";
const LANGUAGE_PAGE_LINK_SEL = "a.cp_LocationPicker__link";
const LANGUAGE_COOKIE_NAME = 'hbs_language';
const LANGUAGE_SELECT_MESSAGE = 'language/select';
const LANGUAGE_REDIRECT_MESSAGE = 'language/redirect';

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

class LanguageManager {

  /**
   *Creates an instance of LanguageManager.
   * @memberof LanguageManager
   */
  constructor() {
    this.bindCustomMessageEvents();

    if(document.querySelector(LANGUAGE_PAGE_SEL) !== undefined){
      window.console.log('on language page');
      this.checkForLanguageCookie();
    }
  }

  /**
   *
   *
   * @param {*} eventData
   * @memberof LanguageManager
   */
  redirectToLanguageSite(eventData) {
    let languageLink;
    let targetURL;
    let language;

    if(eventData.target.nodeName === 'A'){
      languageLink = eventData.target;
    } else {
      languageLink = eventData.target.closest('a');
    }

    targetURL = languageLink.getAttribute('href');
    language = languageLink.dataset.language;

    window.console.log(targetURL);
    window.console.log(language);

    Cookies.set(LANGUAGE_COOKIE_NAME, language, { expires: 9999, path: '/' });
    window.location = targetURL;
  }

  /**
   *
   *
   * @memberof LanguageManager
   */
  checkForLanguageCookie() {
    if(Cookies.get(LANGUAGE_COOKIE_NAME)){
      let cookieValue = Cookies.get(LANGUAGE_COOKIE_NAME);
      let linkSel = '[data-language=' + cookieValue + ']';
      let languageLink = document.querySelector(linkSel).getAttribute('href');

      window.location = languageLink;
    }
  }

  /**
   *
   *
   * @param {*} eventData
   * @memberof LanguageManager
   */
  selectLanguage(eventData) {
    if(Cookies.get(LANGUAGE_COOKIE_NAME)){
      if(Cookies.remove(LANGUAGE_COOKIE_NAME ,{ expires: 14, path: '/' })){
        window.location = eventData.target.getAttribute('href');
      }
    } else {
      window.location = eventData.target.getAttribute('href');
    }
  }

  /**
   *
   *
   * @memberof LanguageManager
   */
  bindCustomMessageEvents() {
    PubSub.subscribe(LANGUAGE_REDIRECT_MESSAGE, (msg,data) => {
      this.redirectToLanguageSite(data);
    });

    PubSub.subscribe(LANGUAGE_SELECT_MESSAGE, (msg,data) => {
      this.selectLanguage(data);
    });
  }
}

/**
 *
 *
 */
function delegateEvents() {
  createGlobalMessenger('click',LANGUAGE_LINK_SEL,LANGUAGE_REDIRECT_MESSAGE, true);
  createGlobalMessenger('click',LANGUAGE_PAGE_LINK_SEL,LANGUAGE_SELECT_MESSAGE, true);
}

/**
 *
 *
 * @export
 */
export default function initLanguageManager() {
  delegateEvents();

  const newLanguageManager = new LanguageManager();
}


